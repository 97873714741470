<template>
  <footer class="footer">
    主办单位：温州瓯江口产业集聚区管理委员会<br /><span style="margin-right: 20px">地址：温州瓯江口产业集聚区行政服务中心</span
    ><span style="margin-right: 20px">电话：0577-88808880</span><span style="margin-right: 20px">邮政编码：325026</span>传真：0577-88362615<br />
    技术支持：中国电信温州分公司
  </footer>
</template>

<script>
export default {
  name: 'PublicFooter',
};
</script>

<style scoped lang="scss">
.footer {
  position: absolute;
  bottom: -168px;
  box-sizing: border-box;
  padding: 30px 0;
  width: 100%;
  height: 168px;
  color: #96a1af;
  background: #0b294f;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.56);
  letter-spacing: 0;
  text-align: center;
  line-height: 36px;
}
</style>

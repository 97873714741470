<template>
  <ul class="anchor-menu">
    <li
        v-for="(item, index) in menu"
        class="item" @click="switchMenuItem(item, index)"
        :key="item.name + item.link"
        :class="{active: Number(activeIndex) === index}">
      <i v-if="item.icon" class="icon"
         :style="{'background-image': Number(activeIndex) !== index ? `url(${item.icon})` : `url(${item.iconActive})`}"/>{{item.name}}
    </li>
  </ul>
</template>

<script>
export default {
  name: "AnchorMenu",
  props: {
    // menu的每一项 包含 link:dom的id， name:menu显示的文字, icon: 图标，icon--active：active时的图标
    menu: {
      type: Array,
      default: _ => []
    },
    // 偏移量 离顶部的距离
    offset: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      activeIndex: 0,
      topArr: []
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        to.query.link && this.scrollTo(to.query.link)
      },
      immediate: true
    }
  },
  methods: {
    switchMenuItem(item, index) {
      this.$router.push({
        query: {
          link: item.link
        }
      })
      this.activeIndex = index
    },
    scrollTo(id) {
      this.$nextTick(() => {
        window.scrollTo({
          top: document.querySelector(id).offsetTop - this.offset,
          behavior: "smooth"
        });
      })
    },
    handleScroll() {
      this.topArr.forEach((item, index) => {
        if (item <= document.documentElement.scrollTop + document.documentElement.clientHeight / 2 + this.offset) {
          this.activeIndex = index
        }
      })
    },
    initMenuTop(menu) {
      return menu.map(item => {
        return document.querySelector(item.link).offsetTop
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.topArr = this.initMenuTop(this.menu)
    })
    window.addEventListener('scroll', this.handleScroll, true)
    this.$once('hook:destroyed', () => {
      window.removeEventListener('scroll', this.handleScroll, true)
    })
  }
}
</script>

<style scoped lang="scss">
@media screen and (max-height: 830px) {
    .anchor-menu {
      transform: scale(0.8);
      top:50px  !important;
    }
}
.anchor-menu {
  float: left;
  z-index: 2000;
  position: sticky;
  top: 100px;
  margin-top: 0;
  margin-left: 83px;
  border: 1px solid #ddd;
  width: 106px;
  text-align: center;
  cursor: pointer;
  background: #fff;
  color: #666;
  .icon {
    display: block;
    margin: 0 auto 10px;
    padding-top: 20px;
    width: 50px;
    height: 50px;
  }

  .item {
    position: relative;
    border-left: 6px solid transparent;
    height: 100px;
    transition: all .3s;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:hover {
      background: #EAF2F9;
    }

    &.active {
      border-left: 6px solid #4393F8;
      background: #EAF2F9;
      color: #333;
    }
  }
}
</style>

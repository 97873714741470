// import Config from '@/assets/js/config.js'

let menuData = [
  { res_name: '办事首页', url: '/EnterpriseService' },
  {
    res_name: '我要入园',
    url: '',
    children: [
      { res_name: '入园申请', url: '/EnterprisesInPark', },
      { res_name: '进度追踪', url: '/Progress' },
      { res_name: '材料管理', url: '/MaterialManagement' },
      { res_name: '施工管理', url: '/ConstructionManagement', },
      { res_name: '企业预登记', url: '/preRegistration' },
      { res_name: '中介商推荐企业', url: '/Intermediaries' },
    ]
  },
  {
    res_name: '智脑决策', url: '',
    children: [
      {
        res_name: '企业档案', url: '',
        children: [
          { res_name: '企业档案馆', url: '/EnterpriseArchives/details', },
          { res_name: '企业画像', url: '/EnterprisePortrait' },
        ]
      },
      {
        res_name: '智能诊断', url: '',
        children: [
          { res_name: '企业评估', url: '/IntelligentDiagnosis', },
          { res_name: '产业评估', url: '/IndustryEvaluation' },
          { res_name: '运营分析', url: '/operating' },
          { res_name: '风控预警', url: '/warning' },
        ]
      },
    ]
  },
  {
    res_name: '政府办事', url: '',
    children: [
      {
        res_name: '政策服务', url: '',
        children: [
          { res_name: ' 政策兑付', url: '/GovService/policy',  },
          { res_name: ' 职称评定', url: 'https://zcps.rlsbt.zj.gov.cn/', open:true,},
          { res_name: ' 人力资源网', url: 'http://www.0577hr.com/index.htm',open:true,  },
        ]
      },
      {
        res_name: '审批服务', url: '',
        children: [
          { res_name: '审批管理', url: '/StaffNeedList', },
          // { res_name: '审批进度', url: '/StaffNeedList' },
        ]
      },
      {
        res_name: '政务服务', url: '',
        children: [
          // { res_name: '企业预报', url: '/EnterpriseReport' },
          { res_name: '智能选址', url: '/SmartLocation', },
         
          { res_name: '员工子女入学申报', url: '/entrance', },
          { res_name: '工伤认定申报', url: '/workInjury', },
          { res_name: '企业招聘', url: '/StaffNeedList' },
          { res_name: '培训活动', url: '/ActivitiesSquare' },
          // { res_name: '企业走访', url: '/ActivitiesSquare' },
        ]
      },
      {res_name: '企业空间资源', url: '/SpaceResource', 
        children: [
            {res_name: '我的资源', url: '/SpaceResource/mine'},
            {res_name: '资源市场', url: '/SpaceResource/market'},
            {res_name: '我的申请', url: '/SpaceResource/apply'},
            {res_name: '我的审批', url: '/SpaceResource/check'},
        ]
      },
    ]
  },
  {
    res_name: '应用超市', url: '',
    children: [
      {
        res_name: '业务发展', url: '',
        children: [
          { res_name: '客源智推', url: '/customersRecommend', },
          { res_name: '行业供求市场', url: '/SupplyandDemand' },
          { res_name: '数字化改造推荐', url: '/DigitalTransform' },
        ]
      },
      {
        res_name: '资金扶持', url: '',
        children: [
          { res_name: '金融超市', url: '/FinancialMarket', },
          { res_name: '产业基金', url: '/IndustrialFund' },
        ]
      },
    ]
  }
]
if (process.env.NODE_ENV === 'development') { // 开发环境下显示Demo菜单
  // let menuDemo = {
  //   res_name: 'Demo',
  //   children: [
  //     { res_name: '欢迎页', url: '/' },
  //     { res_name: '表单页', children: [{ res_name: '表单页', url: '/demo/form' }] },
  //     { res_name: '列表页', children: [{ res_name: '列表一', url: '/demo/list' }] },
  //     { res_name: '详情页', children: [{ res_name: '详情一', url: '/demo/list/details' }] },
  //     { res_name: '结果页', children: [{ res_name: '成功', url: '/demo/success' }] },
  //     { res_name: '异常页', children: [{ res_name: '加载失败', url: '/demo/fail' }, { res_name: '404', url: '/demo/404' }, { res_name: '403', url: '/demo/403' }] }
  //   ]
  // }
  // menuData.unshift(menuDemo)
}

export default menuData

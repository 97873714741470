




















import { $post } from '@/http/axios';
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    name:"ElInputPlus",
    props:{
        field:String,
        value:{
            type:[String,Number]
        },
        precision:{
            type:Number,
            default:2
        },
        /**
         * 输入框append
         */
        append:String,
        /**
         * 短信发送接口
         */
        smsApi:String,
        type:{
            type:String,
            default:'text',
            validator: function (value:string) {
                return ['text', 'number', 'integer','float','textarea','sms'].includes(value)
            }
        }
    },
    data(){
        return {
            countdown:0
        }
    },
    computed:{
        computedInputType(){
            const type = this.type;
            if(['float','integer'].includes(type)){
                /**
                 * 之所以要用text 而不是 number  是因为 撒币 el-input 在 type为 number 情况下
                 * 输入小数点  . 不会触发 @input 事件 从而无法阻止用户输入小数点 啊呜卵
                 */
                return 'text'
            }
            return type;

        },
        val:{
            get():any{
                return this.value;
            },
            set(val: any){
                if(this.type === 'integer') {
                    val = this.parseInteger(val)
                }
                // if(this.type === 'float') {
                //     val = this.parseFloat(val,this.precision)
                // }

                console.log("input-plus更新",val)
                this.$emit("input",val)
            }
        },
    },
    methods:{
        parseInteger(value:number){
             return Number(`${value}`.replace(/[^0-9]/gi,""))
        },
        parseFloat(value:string,precision:number){
            let last  = value.slice(-1);
            let other = value.slice(0,value.length - 1);
            console.log(`输入框keydown`,{value,last,other});
            
            if(/\d/.test(last)) return  other + last

            if(/\./.test(last)){
               return other +  (other.indexOf('.') === -1 ? last  : '')
            }

            return other

            if(other.indexOf('.') === -1){}

            // console.log(`正则匹配浮点`,value)
            // value =  Number(`${value}`.replace(/^([0-9]|[1-9]\d+)(\.\d{2})?$/,""))
            // console.log(`匹配结果`,value)
            return value;
        },
        handleInput(val:any){
            if(this.type === 'float'){
                this.val = this.parseFloat(val,2)
            }
         


        },
        async handleGetMessage(){
            console.log(`获取短信验证码`,this.smsApi,this.val,/^1[3|4|5|6|7|8|9]\d{9}$/.test(this.val));
            if(!this.smsApi){
                console.error("当type为sms时  必须传入 smsApi")
            }
              console.log(`手机号验证:[${this.val}]；验证结果[${(/^1[3|4|5|6|7|8|9]\d{9}$/.test(this.val))}]`)
            if(!(/^1[3|4|5|6|7|8|9]\d{9}$/.test(this.val)) ){
              
                this.$message.error("请输入正确手机号")
                return;
            }
            let {success,message} = await $post(this.smsApi,{[this.field!]:this.val});
            if(!success){
               this.$message.error(message)
               return;
            }
            this.$message.success(`发送成功`)
            this.countdown = 30;
            const timer =  setInterval(()=>{
                this.countdown--
                if(this.countdown <1 ){
                    clearInterval(timer)
                }
            },1000)
        },
        // checkPhoneNumber(rule, value, callback) {
        //     const reg = /^1[3|4|5|6|7|8|9]\d{9}$/;
        //     if (!value) {
        //         return callback(new Error('请填写手机号码！'))
        //     } else if (!reg.test(value)) {
        //         return callback(new Error('请填写正确的手机号码！'))
        //     }else{
        //         callback()
        //     }
        // }
    }
})

// // axios 默认配置
const httpURL = process.env.NODE_ENV === 'production' ? 'https://ojkcyzn.wenzhou.gov.cn:8086/server/' : 'https://ojkcyzn.wenzhou.gov.cn:8086/server/'

export default {
  method: 'get',
  baseURL: process.env.VUE_APP_SERVER_HOST,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    // 'Access-Control-Allow-Origin': '*',
    "Access-Control-Allow-Credentials": false
  },
  data: {},
  timeout: 10000,
  withCredentials: true,
  responseType: 'json'
}

/*eslint-disable*/
import { $get, $post, $dataPost, $put } from './axios'

export default {
    /**
     * 入园申请删除相关
     * @param {number} id
     * @returns 
     */

 
     /**
      * 一般性项目-现有产品-删除
      */
     deleteProjectExistingProducts(id){
        return $get("/wztc/projectExistingProducts/deleteProjectExistingProducts",{idList:id})
     },
     /**
      * 一般性项目-现有设备清单-删除
      */
    deleteProjectEquipment(id){
        return $get("/wztc/projectEquipment/deleteProjectEquipment",{idList:id})
    },
    /**
     * 一般性项目-固定资产计划-流动资金-删除
    */
    deleteLiquidity(id){
        return $get("/wztc/projectFixedInvestment/deleteLiquidity",{idList:id})
    },
    /**
     * 一般性项目-固定资产计划-设备投资-删除
    */
     deleteProjectEquipmentInvestment(id){
        return $get("/wztc/projectFixedInvestment/deleteProjectEquipmentInvestment",{idList:id})
    },
    /**
     * 一般性项目-拟生产经营产品-删除
    */
    deleteProjectWillProducts(id){
        return $get("/wztc/projectWillProducts/deleteProjectWillProducts",{idList:id})
    }, 
    /**
     * 一般性项目-经济效益分析-删除
    */
     deleteProjectBenefit(id){
        return $get("/wztc/projectBenefit/deleteProjectBenefit",{idList:id})
    },
    /**
     * 一般性项目-其他-人员安排-删除
    */
    deleteProjectPersonArrange(id){
        return $get("/wztc/projectPersonArrange/deleteProjectPersonArrange",{idList:id})
    },
    /**
     * 安全应急-经济效益分析-人员安排-删除
    */
     deleteSafeBenefit(id){
        return $get("/wztc/safeProjectBenefit/deleteSafeBenefit",{idList:id})
    },
    /**
     * 安全应急-经济效益分析-删除
    */
     deleteSafeBenefit(id){
        return $get("/wztc/safeProjectBenefit/deleteSafeBenefit",{idList:id})
    },
    /**
     * 安全应急-其他-人员安排-删除
    */
     deleteSafePersonArrange(id){
        return $get("/wztc/safeProjectPersonArrange/deleteSafePersonArrange",{idList:id})
    },





    /**
     * 获取分页安全应急-未来三年营收预估 详情列表
     * @param {number} [page] 页码
     * @param {!string} projectId  项目id
     * @param {number} [rows]  每页条数
     * @returns {Promise<Result>}
     */
    findSafeProjectRevenueThreeYearsPage({page=1,projectId,rows=10}){
        return $post("/safeprojectrevenuethreeyears/findSafeProjectRevenueThreeYearsPage",{page,projectId,rows})
    },

    
    /**
     * @typedef RevenueThreeYearsItem
     * @property {string} [id]  id选填  有id是更新，无id为新增
     * @property {!string} projectId  项目id必填
     * @property {string} year 年份
     * @property {number} salesRevenue 销售收入
     * @property {number} taxRevenue 税收
     * @property {number} profit 利润
     * @property {number} permanentPersonnel 常驻人员
     */
    /**
     * 编辑 未来三年营收预估 详情列表
     * @param {Array<RevenueThreeYearsItem>}  dataList 列表
     * @returns {Promise<Result>}
     */
     editSafeProjectRevenueThreeYears(dataList=[]){
        return $post("/safeprojectrevenuethreeyears/editSafeProjectRevenueThreeYears",{
            list:JSON.stringify(dataList)
        })
    }, 
    /**
     * 获取编辑安全应急项目 前后三年营收数据的表格数据
     * @param {number} projectId  项目ID
     * @returns 
     */
     getEditSafeProjectLastAndNextThreeYears({projectId}){
        return $post("/safeprojectthreeyears/editSafeProjectThreeYears",{projectId})
    },
    /**
     * 保存前后营收数据
     * @param {any[]} list 
     * @returns 
     */
    saveSafeProjectThreeYears(list=[]){
        return $post("/safeprojectthreeyears/saveSafeProjectThreeYears",{
            list:JSON.stringify(list)
        })
    },

  
    getkey(params) {
        return $post('/dict/list', params)
    },
    getactivityList() {
        return $get('/wztc/activity/list')
    },
    getactivity(params) {
        return $get('/wztc/activity/' + params)
    },
    activitySignUp(params) {
        return $post('/wztc/activitySignUp', params)
    },
    getActivitySchedule() {
        return $get('/wztc/activity/getActivitySchedule')
    },
    getcorporateinfoList(params) {
        return $get('/wztc/corporateinfo/list', params)
    },
    getcorporateinfo(params) {
        return $get('/wztc/corporateinfo/' + params)
    },
    addcorporateinfo(params) {
        return $post('/wztc/corporateinfo', params)
    },
    editcorporateinfo(params) {
        return $post('/wztc/corporateinfo/edit', params)
    },
    getemployeechilddeclaratList(params) {
        return $get('/wztc/employeechilddeclarat/list', params)
    },
    getemployeechilddeclaratInfo(params) {
        return $get('/wztc/employeechilddeclarat/getInfo', params)
    },
    /**
     * 员工子女入学申报表 批量提交
     * @param {string} ids 
     * @returns 
     */
    employeechilddeclaratBatchSub:data=>$post('/wztc/employeechilddeclarat/batchSub',data),

    /**
     * 获取员工子女入学申报表 审核信息
     * @param {*} id 
     * @returns 
     */
    getEmployeeChilddeClarat:id=>$get(`/wztc/employeechilddeclarat/review?id=${id}`),


    addemployeechilddeclarat(params) {
        return $post('/wztc/employeechilddeclarat/save', params)
    },
    editemployeechilddeclarat(params) {
        return $post('/wztc/employeechilddeclarat/save', params)
    },
    delemployeechilddeclarat(params) {
        return $post('/wztc/employeechilddeclarat/deletes' , params)
    },
    
    getAttachmentShowUrl(params) {
        return $post('/fileUpload/getAttachmentShowUrl', params )
    },
    getToken(params) {
        return $post('/getToken', { 'code': params })
    },
    async getInfo() {
        let res = await $get('/getInfo');
        const {success, result:userInfo} = res;
        if(success) localStorage.setItem("SOCIAL_CODE",userInfo.code)
        // console.log({userInfo});
        return res;
    },
    userEdit(params) {
        return $post('/wztc/user/editUser', params)
    },
    employeeLogin(params) {
        if(params.ticket){ //个人登录
          return $get("/personaluserssologin",params)
        }else{
          return $post('/wztc/user/userLogin', params)
        }
    },
    loginOutPersonalLogin(){
        // return $get("https://puser.zjzwfw.gov.cn/sso/usp.do?action=logoutBack&servicecode=ojkcyznxt")
        return $get("https://puser.zjzwfw.gov.cn/sso/newusp.do?action=logout&servicecode=ojkcyznxt")
    },
    fillInList(str, params) {
        return $get('/enterpriseFillInController/fillInList', params)
    },
    fillInDetailList(params) {
        return $get('/enterpriseFillInController/findEnterpriseFillInDetailList', params)
    },
    submitFillIn(params) {
        return $get('/enterpriseFillInController/submitEnterpriseFillIn', params)
    },
    FillInDetail(params) {
        return $post('/enterpriseFillInController/editEnterpriseFillInDetail', params)
    },
    getBuildinglease(params) {
        return $post('/buildingleasemanagement/findBuildingLeaseNumListByLand', params)
    },
    getBuildingleaseList(params) {
        return $post('/buildingleasemanagement/findBuildingLeaseListByBuildNO', params)
    },
    buildingLeaseApply(params) {
        let data = Object.assign({},params);
        for(let key in data){ // 如果存在数组或对象 进行转JSON字符串操作
            if(data[key] instanceof Object){
                data[key]  = JSON.stringify(data[key])
            }
        }
        return $post('/buildingleaseapply/buildingLeaseApplyForEnterprise', data)
    },
    findFloorIndustryAndIncome(params) {
        return $post('/buildingleasemanagement/findFloorIndustryAndIncome', params)
    },
    findFloorLeaseDetailByFloorNO(params) {
        return $post('/buildingleasemanagement/findFloorLeaseDetailByFloorNO', params)
    },
    //废弃
    getProjectIdByToken(params) {
        throw({message:"接口[/wztc/projectBasic/getProjectIdByToken]已废弃 请勿调用",})
        return $get('/wztc/projectBasic/getProjectIdByToken', params)
    },
     //废弃
     getSafeProjectId(params) {
         throw(new Error("接口[/wztc/safeProjectBasic/getProjectIdByToken]已废弃 请勿调用"))
        return $get('/wztc/safeProjectBasic/getProjectIdByToken', params)
    },
    editProjectBasic(data) {
        return $post('/wztc/projectBasic/edit', data)
    },
    getProjectBasic(params) {
        return $get('/wztc/projectBasic/' + params)
    }, 
    findPolicySupportInfoPage() {
        return $post('/policysupportinfo/findPolicySupportInfoPage')
    },
    editProjectExistingProducts(params) {
        return $post('/wztc/projectExistingProducts/edit', params)
    },
    getProjectExistingProducts(params) {
        return $get('/wztc/projectExistingProducts/' + params)
    },
    editProjectEquipment(params) {
        return $post('/wztc/projectEquipment/edit', params)
    },
    getProjectEquipment(params) {
        return $get('/wztc/projectEquipment/' + params)
    },
    editProjectWillInvested(params) {
        return $post('/wztc/projectWillInvested/edit', params)
    },
    getProjectWillInvested(params) {
        return $get('/wztc/projectWillInvested/' + params)
    },
    editProjectWillProducts(params) {
        return $post('/wztc/projectWillProducts/edit', params)
    },
    getProjectWillProducts(params) {
        return $get('/wztc/projectWillProducts/' + params)
    },
    editProjectBenefit(params) {
        return $post('/wztc/projectBenefit/edit', params)
    },
    getProjectBenefit(params) {
        return $get('/wztc/projectBenefit/' + params)
    },
    editProjectPersonArrange(params) {
        return $post('/wztc/projectPersonArrange/edit', params)
    },
    getProjectPersonArrange(params) {
        return $get('/wztc/projectPersonArrange/' + params)
    },
    editSafeProjectBasic(params) {
        return $post('/wztc/safeProjectBasic/edit', params)
    },
    getSafeProjectBasic(params) {
        return $get('/wztc/safeProjectBasic/' + params)
    },
   
    editSafeProjectWillInvested(params) {
        return $post('/wztc/safeProjectWillInvested/edit', params)
    },
    getSafeProjectWillInvested(params) {
        return $get('/wztc/safeProjectWillInvested/' + params)
    },
    editSafeProjectBenefit(params) {
        return $post('/wztc/safeProjectBenefit/edit', params)
    },
    getSafeProjectBenefit(params) {
        return $get('/wztc/safeProjectBenefit/' + params)
    },
    editSafeProjectPersonArrange(params) {
        return $post('/wztc/safeProjectPersonArrange/edit', params)
    },
    getSafeProjectPersonArrange(params) {
        return $get('/wztc/safeProjectPersonArrange/' + params)
    },
    editSafeProjectEnergyRequire(params) {
        return $post('/wztc/safeProjectEnergyRequire/edit', params)
    },
    getSafeProjectEnergyRequire(params) {
        return $get('/wztc/safeProjectEnergyRequire/' + params)
    },
    editProjectFixedInvestment(params) {
        return $post('/wztc/projectFixedInvestment/edit', params)
    },
    getProjectFixedInvestment(params) {
        return $get('/wztc/projectFixedInvestment/' + params)
    },
    getProjectProcessProjectId(params,type) {
        if(type === 'safe'){
            return $get('/wztc/safeProjectBasic/getProjectProcessProjectId' , params)
        }
        return $get('/wztc/projectBasic/getProjectProcessProjectId' , params)
    },
    changeProjectContractInvestAgreeStatus(params) {
        return $get('/wztc/projectBasic/changeProjectContractInvestAgreeStatus' , params)
    },
    downloadContract(params) {
        return $get('/wztc/projectBasic/downloadContract' , params)
    },
    getEnterpriseTemplateMaterialTreeList(params) {
        return $post('/enterprisetemplatematerial/getEnterpriseTemplateMaterialTreeList', params)
    },
    getEnterpriseTemplateMaterialById(name,params) {
        return $post('/enterprisetemplatematerial/getEnterpriseTemplateMaterialById', params)
    },
    getEnterpriseMaterialUploadTreeList(name,params) {
        return $post('/enterprisetemplatematerial/getEnterpriseMaterialUploadTreeList', params)
    },
    saveEnterpriseMaterialList(params) {
        return $post('/enterprisetemplatematerial/saveEnterpriseMaterialList', params)
    },
    getEnterpriseMaterialList(name,params) {
        return $post('/enterprisetemplatematerial/getEnterpriseMaterialList', params)
    },
    deleteEnterpriseMaterial(params) {
        return $post('/enterprisetemplatematerial/deleteEnterpriseMaterial', params)
    },
    deleteWorkInjuryDeclareById(params) {
        return $post('/workinjurydeclare/deleteWorkInjuryDeclareById', params)
    },
    editWorkInjuryDeclare(params) {
        return $post('/workinjurydeclare/editWorkInjuryDeclare', params)
    },
    findWorkInjuryDeclarePage(params) {
        return $post('/workinjurydeclare/findWorkInjuryDeclarePage', params)
    },
    getWorkInjuryDeclareById(params) {
        return $post('/workinjurydeclare/getWorkInjuryDeclareById', params)
    },
    deleteExactEnterpriseRecommendById(params) {
        return $post('/exactenterpriserecommend/deleteExactEnterpriseRecommendById', params)
    },
    editExactEnterpriseRecommend(params) {
        return $post('/exactenterpriserecommend/editExactEnterpriseRecommend', params)
    },
    findExactEnterpriseRecommendPage(params) {
        return $post('/exactenterpriserecommend/findExactEnterpriseRecommendPage', params)
    },
    getExactEnterpriseRecommendById(params) {
        return $post('/exactenterpriserecommend/getExactEnterpriseRecommendById', params)
    },
    // 一般性项目清单获取
    findProjectList(params) {
        return $get('/wztc/projectBasic/findProjectList', params)
    },
    //安全应急项目清单获取
    /**
     * @typedef {Object} QueryTableParams
     * 
     */
    /**
     * 
     * @param {QueryTableParams} params 
     * @returns 
     */
    findSafeProjectList(params){
        return $get('/wztc/safeProjectBasic/findProjectList', params)
    },
    importExcel(params) {
        return $post('/wztc/employeechilddeclarat/importExcel', params)
    },
    getTemplateAttachmentList(params) {
        return $post('/fileUpload/getTemplateAttachmentList', params)
    },
    getProjectCheckDetailListByProjectId(params) {
        return $get('/wztc/projectBasic/getProjectCheckDetailListByProjectId', params)
    },
    submitDraftProjectById({projectId}){
        return $get(`/wztc/projectBasic/submitDraftProjectById/`,{projectId})
    },
    submitDraftSafeProjectById({projectId}){
        return $get(`/wztc/safeProjectBasic/submitDraftProjectById/`,{projectId})
    },
    getSafeProjectCheckDetailListByProjectId(params) {
        return $get('/wztc/safeProjectBasic/getProjectCheckDetailListByProjectId', params)
    },
    editExactAlternativeEnterpriseLibrary(data) {
        return $post('/exactalternativeenterpriselibrary/editExactAlternativeEnterpriseLibrary', data,data)
    },
    findExactAlternativeEnterpriseLibraryPage(params) {
        return $post('/exactalternativeenterpriselibrary/findAlternativeEnterpriseLibraryPageForEn', params)
    },
    getExactAlternativeEnterpriseLibraryById(params) {
        return $post('/exactalternativeenterpriselibrary/getExactAlternativeEnterpriseLibraryById', params)
    },
    deleteExactAlternativeEnterpriseLibraryById(params) {
        return $post('/exactalternativeenterpriselibrary/deleteExactAlternativeEnterpriseLibraryById', params)
    },
    findFinancialMarketApplyPage(params) {
        return $post('/bankcredit/findFinancialMarketApplyPage', params)
    },
    
}

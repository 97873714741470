
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path:"/auth",
    name:"Auth",
    component:()=>import("../pages/auth/index.vue"),
    meta:{title:"授权"},
  },
  {
    path:'/EnterpriseArchives',
    name:"EnterpriseArchives",
    component:()=>import("../pages/EnterpriseArchives/index.vue"),
    redirect:"/EnterpriseArchives/info",
    meta:{title:"企业档案馆"},
    children: [
     
      {
        path:'info',
        name:"EnterpriseArchivesInfo",
        component:()=>import("../pages/EnterpriseArchives/info.vue"),
        meta:{title:"企业信息"}
      },
      {
        path:'portrait',
        name:"EnterpriseArchivesPortrait",
        component:()=>import("../pages/EnterpriseArchives/portrait.vue"),
        meta:{title:"亩均画像"}
      },
      {
        path:'analysis',
        name:"EnterpriseArchivesAnalysis",
        component:()=>import("../pages/EnterpriseArchives/analysis.vue"),
        meta:{title:"分析评价"}
      }
    ]
  },
  {
    path:'/SpaceResource',
    name:"SpaceResource",
    component:()=>import("../pages/SpaceResource/index.vue"),
    redirect:"/SpaceResource/mine",
    meta:{title:"企业空间资源"},
    children: [
     
      {
        path:'mine',
        name:"SpaceResourceMine",
        component:()=>import("../pages/SpaceResource/mine.vue"),
        meta:{title:"我的资源"}
      },
      {
        path:'/SpaceResource/market',
        name:"SpaceResourceMarket",
        component:()=>import("../pages/SpaceResource/market.vue"),
        meta:{title:"资源市场"}
      },
      {
        path:'/SpaceResource/check',
        name:"SpaceResourceCheck",
        component:()=>import("../pages/SpaceResource/check.vue"),
        meta:{title:"待我审批"}
      },
      {
        path:'/SpaceResource/apply',
        name:"SpaceResourceApply",
        component:()=>import("../pages/SpaceResource/apply.vue"),
        meta:{title:"我的申请"}
      },
    ]
  },
 
  {
    path:'/GovService/policy',
    name:"GovServicePolicy",
    component:()=>import("../pages/GovService/policy.vue"),
    meta:{title:"政策兑付"}
  },
  {
    path: '/',
    name: 'HomePage',
    component: () => import('../pages/HomePage/index'),
    meta: { activeMenu: '/' }
  },
  { path: '/iframe/:iframeUrl', name: 'iframeUrl', component: () => import('../pages/iframe/index'), }, // 外链内嵌到右侧iframe
  {
    path: '/EnterpriseService',
    name: 'EnterpriseService',
    component: () => import('../pages/EnterpriseService/index'),
    meta: { activeMenu: '/EnterpriseService' }
  },
  {
    path: '/EnterprisesInPark',
    name: 'EnterprisesInPark',
    component: () => import('../pages/EnterprisesInPark/index'),
  },
  {
    path: '/ProjectList',
    name: 'ProjectList',
    component: () => import('../pages/EnterprisesInPark/list/index'),
    meta: { activeMenu: '/ProjectList' }
  },
  {
    path: '/Security/:id?',
    name: 'Security',
    component: () => import('../pages/EnterprisesInPark/security/index'),
    meta: { activeMenu: '/EnterprisesInPark' }
  },
  {
    path: '/General/:id?',
    name: 'General',
    component: () => import('../pages/EnterprisesInPark/general/index'),
    meta: { activeMenu: '/EnterprisesInPark' }
  },
  {
    path: '/Progress/:id',
    name: 'Progress',
    component: () => import('../pages/EnterprisesInPark/progress/index'),
  },
  {
    path: '/MaterialManagement',
    name: 'MaterialManagement',
    component: () => import('../pages/EnterprisesInPark/MaterialManagement/index'),
  },
  {
    path: '/ConstructionManagement',
    name: 'ConstructionManagement',
    component: () => import('../pages/EnterprisesInPark/ConstructionManagement/index'),
  },
  {
    path: '/Gant',
    name: 'Gant',
    component: () => import('../pages/EnterprisesInPark/ConstructionManagement/Gant'),
    meta: { activeMenu: '/EnterprisesInPark' }
  },
  {
    path: '/ActivitiesSquare',
    name: 'ActivitiesSquare',
    component: () => import('../pages/ActivitiesSquare/index'),
  },
  {
    path: '/SupplyandDemand',
    name: 'SupplyandDemand',
    component: () => import('../pages/SupplyandDemand/index'),
  },
  {
    path: '/SupplyandDemand/demandDetails',
    name: 'demandDetails',
    component: () => import('../pages/SupplyandDemand/demandDetails'),
  },
  {
    path: '/SupplyandDemand/demandRelease',
    name: 'demandRelease',
    component: () => import('../pages/SupplyandDemand/demandRelease'),
  },
  {
    path: '/SupplyandDemand/demandList',
    name: 'demandList',
    component: () => import('../pages/SupplyandDemand/demandList'),
  },
  {
    path: '/ActivityDetail/:id',
    name: 'ActivityDetail',
    component: () => import('../pages/ActivitiesSquare/ActivityDetail/index'),
  },
  {
    path: '/StaffNeedShow',
    name: 'StaffNeedShow',
    component: () => import('../pages/PolicyService/StaffNeedShow/index'),
  },
  {
    path: '/StaffNeedDetail/:id',
    name: 'StaffNeedDetail',
    component: () => import('../pages/PolicyService/StaffNeedDetail/index'),
  },
  {
    path: '/StaffNeedList',
    name: 'StaffNeedList',
    component: () => import('../pages/PolicyService/StaffNeedList/index'),
  },
  {
    path: '/customersRecommend',
    name: 'customersRecommend',
    component: () => import('../pages/EnterpriseService/customersRecommend'),
  },
  {
    path: '/Intermediaries',
    name: 'Intermediaries',
    component: () => import('../pages/Intermediaries/index'),
  },
  {
    path: '/IntermediariesDetail/:id',
    name: 'IntermediariesDetail',
    component: () => import('../pages/Intermediaries/detail'),
  },
  {
    path: '/preRegistration',
    name: 'preRegistration',
    component: () => import('../pages/preRegistration/index'),
  },
  {
    path: '/preRegistrationDetail/:id',
    name: 'preRegistrationDetail',
    component: () => import('../pages/preRegistration/detail'),
  },
  {
    path: '/entrance',
    name: 'entrance',
    component: () => import('../pages/PolicyService/entrance/index'),
  },
  {
    path: '/workInjury',
    name: 'workInjury',
    component: () => import('../pages/PolicyService/workInjury/index'),
  },
  {
    path: '/SmartLocation',
    name: 'SmartLocation',
    component: () => import('../pages/PolicyService/smartLocation/index'),
  },
  {
    path: '/SmartLocation/tools',
    name: 'SmartLocation/tools',
    component: () => import('../pages/PolicyService/smartLocation/tools'),
  },
  {
    path: '/roomSelector',
    name: 'roomSelector',
    component: () => import('../pages/PolicyService/smartLocation/houseChoose'),
  },
  {
    path: '/selectBuilding/:land',
    name: 'selectBuilding',
    component: () => import('../pages/PolicyService/smartLocation/selectBuilding'),
    meta: { activeMenu: '/SmartLocation' }
  },
  {
    path: '/selectHouse/:land/:build',
    name: 'selectHouse',
    component: () => import('../pages/PolicyService/smartLocation/selectHouse'),
    meta: { activeMenu: '/SmartLocation' }
  },
  /**
   * selectFactory 厂房选择 其实跟 selectHouse差不多 但是 由于selectHouse逻辑捋起来很麻烦 所以
   * 另起炉灶了 
   */
  {
    path: '/selectFactory/:land/:build',
    name: 'selectFactory',
    component: () => import('../pages/PolicyService/smartLocation/selectFactory'),
    meta: {desc:"标准厂房选择", activeMenu: '/SmartLocation' }
  },
  {
    path: '/smartLocationOverview/:land/:build',
    name: 'smartLocationOverview',
    component: () => import('../pages/PolicyService/smartLocation/overview'),
    meta: { activeMenu: '/SmartLocation' }
  },
  // {
  //   path: '/EnterpriseReport',
  //   name: 'EnterpriseReport',
  //   component: () => import('../pages/PolicyService/EnterpriseReport/index'),
  // },
  // {
  //   path: '/Report/:id',
  //   name: 'Report',
  //   component: () => import('../pages/PolicyService/EnterpriseReport/report'),
  // },
  {
    path: '/FinancialMarket',
    name: 'FinancialMarket',
    component: () => import('../pages/FinancialMarket/index'),
  },
  {
    path: '/FinancialMarkets',
    name: 'FinancialMarkets',
    component: () => import('../pages/FinancialMarket/index2'),
  },
  {
    path: '/FinancialDetail',
    name: 'FinancialDetail',
    component: () => import('../pages/FinancialMarket/details'),
  },
  {
    path: '/FinancialList',
    name: 'FinancialList',
    component: () => import('../pages/FinancialMarket/list'),
  },
  {
    path: '/FinancialList/application',
    name: 'FMApplication',
    component: () => import('../pages/FinancialMarket/application'),
  },
  {
    path: '/IndustrialFund',
    name: 'IndustrialFund',
    component: () => import('../pages/IndustrialFund/index'),
  },
  {
    path: '/IndustrialFund/declare/:id?',
    name: 'IFDeclare',
    meta:{title:'项目申报'},
    component: () => import('../pages/IndustrialFund/declare'),
  },
  {
    path: '/IndustrialFund/list',
    name: 'IFList',
    meta:{title:"项目清单"},
    component: () => import('../pages/IndustrialFund/list'),
  },
  {
    path: '/EnterpriseArchives/details',
    name: 'EADetails',
    component: () => import('../pages/brainDecision/EnterpriseArchives/details'),
  },
  {
    path: '/DigitalTransform',
    name: 'DigitalTransform',
    component: () => import('../pages/DigitalTransform/index')
  },
  {
    path: '/DigitalTransform/detail',
    name: 'DTDetails',
    component: () => import('../pages/DigitalTransform/detail')
  },
  {
    path: '/DigitalTransform/list',
    name: 'DTList',
    component: () => import('../pages/DigitalTransform/list')
  },
  {
    path: '/EnterprisePortrait',
    name: 'EnterprisePortrait',
    component: () => import('../pages/brainDecision/enterprisePortrait/index'),
  },
  {
    path: '/warning',
    name: 'warning',
    component: () => import('../pages/brainDecision/warning/index'),
  },
  {
    path: '/operating',
    name: 'operating',
    component: () => import('../pages/brainDecision/operating/index'),
  },
  {
    path: '/IndustryEvaluation',
    name: 'IndustryEvaluation',
    component: () => import('../pages/brainDecision/industryEvaluation/index'),
  },
  {
    path: '/IntelligentDiagnosis',
    name: 'IntelligentDiagnosis',
    component: () => import('../pages/IntelligentDiagnosis/index')
  },
  {
    path: '/UserManage',
    name: 'UserManage',
    component: () => import('../pages/UserManage/index'),
    children: [],
  },
]

const router = new VueRouter({
  routes,
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
export default router

export const isValidPhone = (str:string):boolean => /^1[3|4|5|7|8][0-9]\d{8}$/.test(str);

export const phoneRuleValidator =  (rule:any, value:string, callback:Function) => {
    if(!isValidPhone(value)){
        callback(new Error("请输入正确的手机号码"))
        return;
    }
    callback()
  };

export const setStorage = (key:string, val:any):void=> localStorage.setItem(key, JSON.stringify(val)) ;
export const getStorage = <T>(key:string) =>(localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)!) : null) as T;

export function  getQuery<T = string>(key?:string): T | undefined{ //获取URL中的query参数
  let raw:string =  window.location.hash.split("?")[1] || window.location.search.split("?")[1]
  if(!raw) return
  const query:{[k:string]:any} =raw.split("&").reduce((query,c)=>{
      const kv =  c.split("=")
      // query[kv[0]] = kv[1]
      query = {...query,[kv[0]]:kv[1]}
      return query
  },{});
  return key ? query[key] : query
}
export const Base64 = {
   encode:(str:string)=>btoa(encodeURIComponent(str)),
   decode:(str:string)=>decodeURIComponent(atob(str))
}
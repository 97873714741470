import axios from 'axios'
import config from './config'
import {Message} from 'element-ui'
import store from '../store'

// import Cookies from 'js-cookie'
import { getToken,setToken,removeToken } from '@/http/auth';
import qs from 'qs'

function $axios (options) {
  // const token = Cookies.get('token')
  // const token = ''
  // options.url = token && token !== 'undefined' ? `${options.url}?token=${token}` : options.url
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: config.baseURL,
      headers: config.headers,
      withCredentials: true, // send cookies when cross-domain requests
    })
    // 请求拦截 通常在这里处理 公共的请求头 如配合cookies添加token
    instance.interceptors.request.use(config => {
      // config.params = qs.stringify(config.params)
      if(getToken()){
        config.headers={
            'Authorization':getToken() || ''
        }
      }
      return config
    },
    error => {
      // 此处可以配合路由 重定向错误页面
      return Promise.reject(error)
    })
    // 响应拦截 在一个项目中与后端约定好通用的数据格式 可以在这里做通用化的处理
    instance.interceptors.response.use(res => {
      if (res.headers.verifytoken) {
        setToken(res.headers.verifytoken)
      }
      if (res.data.code === 2000) {
        return res.data
      } else {
        if (res.data.code === 1030102||res.data.code === 1030103) { // 未登录
          // window.location.href = res.data.result + '?redirect=' + encodeURIComponent(window.location.href)
          removeToken()
          store.commit('updateValue', { userStore: null })
          Message({ type: 'error', message: res.data.message,offset:80 })
          return Promise.reject()
        }
        // console.error(res)
        return res.data

      }
    },
    error => {
      console.error(error)
      return Promise.reject(error)
    })
    instance(options).then(res => {
      resolve(res)
      return false
    }).catch(error => {
      reject(error)
    })
  })
}

// $get
export function $get (url, params = {}) {
  return $axios({
    url: `${url}`,
    params
  })
}

export function $post (url, params = {},data={}) {
  // let params = {},body = {}
  // if(data.params || data.body){
  //    params =  data.params || {};
  //    body = data.body || {}
  // }else{
  //   params = data
  // }
  return $axios({
    params:params,
    // body,
    url: `${url}`,
    method: 'POST',
    data,
    paramsSerializer: function(p) { //固定写法
      return qs.stringify(p, {arrayFormat: 'repeat'})
    }
  })
}

export function $dataPost (url, data = {}) {
  return $axios({
    data,
    url: `${url}`,
    method: 'POST',
    paramsSerializer: function(p) { //固定写法
      return qs.stringify(p, {arrayFormat: 'repeat'})
    }
  })
}

export function $put (url, params = {}) {
  return $axios({
    params: params,
    url: `${url}`,
    method: 'PUT'
  })
}

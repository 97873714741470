import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import api from './http'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui'
import AnchorMenu from "@/components/AnchorMenu";
import ECharts from 'vue-echarts'
import VueCompostionAPI from '@vue/composition-api'
import 'echarts'
import http from '@/http/api';
import {
  getToken,
  setToken,
  removeToken
} from '@/http/auth';
import {getQuery} from "@/common/utils"
import ElInputPlus from "@/components/el-input-plus.vue"
Vue.component('AnchorMenu', AnchorMenu)
Vue.component('v-chart', ECharts)
Vue.component('el-input-plus', ElInputPlus)
Vue.prototype.$echarts = ECharts
Vue.config.productionTip = false
Vue.use(api)
Vue.use(VueCompostionAPI)
import md5 from "js-md5";
window.md5 = md5
        
Vue.use(ElementUI)
// Vue.prototype.$message = ElementUI.Message

window.vBus = new Vue() // 注册全局事件对象

router.beforeEach((to, from, next) => {
  let name = to.name
  let token = getToken()
  if(to.name === 'Auth'){
    next();
    return;
  }
  if (token) {
    if (!store.state.userStore) {
      http.getInfo().then((res) => {
        if (res.code === 2000) {
          if (res.result) {
            store.commit('updateValue', { userStore: res.result })
          } else {
            store.commit('updateValue', { userStore: null })
            if (name != 'HomePage' && name != 'EnterpriseService' && name != 'EnterprisesInPark' && name != 'StaffNeedShow' && name != 'FinancialMarkets' && name != 'iframeUrl') {
              window.vBus.$emit('logIn');
              return
            }
          }
        }
      }); //获取用户信息
    }
  } else {
    let query =  getQuery() || {}  // window.location.search.substring(1);
    console.log("获取code",query)
    // let que
    // let vars = query.split('&');
    // for (let i = 0; i < vars.length; i++) {
    //   let pair = vars[i].split('=');
    //   if (pair[0] == 'code') {
    //     code = pair[1];
    //   }
    // }
    // console.log("获取code",code)
   const {code,ticket} = query;
    if (code) {
      if(ticket)localStorage.setItem("SSO_TICKET",ticket)
      http.getToken(code).then((res) => {
        if (res.success) {
          //获取用户信息 请求头key Authorization
          setToken(res.result);
          window.location.href = window.location.origin + window.location.pathname + window.location.hash;
        }
      });
    } else if (name != 'HomePage' && name != 'EnterpriseService' && name != 'EnterprisesInPark' && name != 'StaffNeedShow' && name != 'FinancialMarkets' && name != 'iframeUrl') {
      window.vBus.$emit('logIn');
      return
    }
  }
  next()
})
router.afterEach((to, from) => {
  document.documentElement.scrollTop = 0
})
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

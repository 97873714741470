<template>
  <el-dialog title="员工登录" :visible.sync="centerDialogVisible" width="400px" center>
    <el-form ref="loginForm" :model="loginData" :rules="loginRules" v-if="centerDialogVisible">
      <el-form-item prop="userName">
        <el-input placeholder="请输入您的账号" prefix-icon="el-icon-user" v-model="loginData.userName"> </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input placeholder="请输入您的密码" prefix-icon="el-icon-lock" v-model="loginData.password" show-password> </el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 16px">
        <el-button type="primary" @click="employeeLogin">登 录</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleToPersonalLogin">个人登录</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="SSO">法人登录</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import config from '@/http/config';
import { setToken } from '@/http/auth';
export default {
  data() {
    return {
      centerDialogVisible: false,
      loginData: {},
      loginRules: {
        userName: [{ required: true, message: '请输入员工账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
    };
  },
  methods: {
    handleToPersonalLogin(){
      const url = window.location.href;
      // https://puser.zjzwfw.gov.cn/sso/usp.do?action=register&servicecode=【接入应用系统接入代码】&goto=【接入应用系统跳转地址】
      window.location.href = `https://puser.zjzwfw.gov.cn/sso/newusp.do?action=ssoLogin&servicecode=ojkcyznxt&goto=${url}`;
      // window.location.href = ` https://puser.zjzwfw.gov.cn/sso/usp.do?action=register&servicecode=ojkcyznxt&goto=${url}`;
    },
    SSO() {
      //用户登录
      const url = window.location.href;
      window.location.href = `http://esso.zjzwfw.gov.cn/opensso/spsaehandler/metaAlias/sp?spappurl=http://ojkcyzn.wenzhou.gov.cn:8818/login?goto=${url}`;
    },
    employeeLogin() {
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          this.$api.employeeLogin(this.loginData).then((res) => {
            //TODO 登录完成后存储token
            if (res.success) {
              setToken(res.result.token);
              this.$message.success({ message: '登录成功', offset: 80 });
              this.centerDialogVisible = false;
              this.$api.getInfo().then((res) => {
                if (res.code === 2000) {
                  if (res.result) {
                    console.log("获取用户信息",res.result)
                    this.$store.commit('updateValue', { userStore: res.result });
                  } else {
                    this.$store.commit('updateValue', { userStore: null });
                  }
                }
              }); //获取用户信息
            } else {
              this.$message.error({ message: res.message, offset: 80 });
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.el-dialog__header {
  padding: 0;
  height: 100px;
  line-height: 100px;
  background: url(../assets/img/loginModel/login_topimg@2x.png) center no-repeat;
  background-size: 100% 100%;
}

.el-dialog__title {
  font-size: 24px;
  color: #333333;
  font-weight: 600;
}

.el-dialog__body {
  padding: 14px 60px !important;
  font-size: 16px;
}

.el-button {
  width: 100%;
}

.el-button--default {
  border: 1px solid #0e459c;
  color: #2b5ba8;
}

.el-button--default:hover,
.el-button--default:active {
  border: 1px solid #0e459c;
  color: #2b5ba8;
}

.el-button--primary {
  background-image: linear-gradient(90deg, #207bcb 0%, #0e459c 100%);
}

.el-button--primary:hover,
.el-button--primary:active {
  background-image: linear-gradient(90deg, #207bcb 0%, #0e459c 100%);
}

.el-input__icon::before {
  display: none;
}

.el-icon-user {
  background: url(../assets/img/loginModel/user.png) center no-repeat;
}

.el-icon-pwd {
  background: url(../assets/img/loginModel/lock.png) center no-repeat;
}
</style>
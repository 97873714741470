































import { computed, defineComponent, ref } from '@vue/composition-api'
import {mapState} from "vuex"

export default defineComponent({
    computed:{
        ...mapState(["curRoute"])
    },
    setup(prop,ctx) {
        // const {$store:store,$router} = root;
        // console.log({store})
      const debugForm = ref({
        skipField:false,
        skipFile:false
      })
    //   const currnetPage = computed(()=>ctx.root.$store.state.curRoute || {})
      const env = ref(1)
    //   console.log("路由打印",currnetPage)
      return {debugForm,env}
    },
})

<template>
  <el-drawer title="消息中心" :visible.sync="show" direction="rtl" append-to-body :size="`341`">
    <div class="notice-list content-body">
      <div class="notice-item fl-box" v-for="(item, index) in list" :key="index" v-show="item.show">
        <i class="notice-icon el-icon-success" v-if="item.status == 1"></i>
        <i class="notice-icon el-icon-info" v-if="item.status == 2"></i>
        <i class="notice-icon el-icon-warning" v-if="item.status == 3"></i>
        <div class="fl-1">
          <div class="notice-title">{{ item.title }}</div>
          <div>{{ item.content }}</div>
          <div class="btns" align="right" v-if="item.status == 2">
            <el-button @click="hideItem(item, index)">稍后提醒</el-button>
            <el-button type="primary" @click="go(item.name)">立即查看</el-button>
          </div>
          <el-button type="primary" class="fr" style=" margin-top: 20px" v-if="item.status == 3" @click="go(item.name)"
            >查看</el-button
          >
        </div>
        <i class="close el-icon-close" @click="hideItem(item, index)"></i>
      </div>
      <img src="../assets/img/loginModel/default-07@2x.png" alt="" class="no-notoce" v-if="nodata" />
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: 'drawer',
  data() {
    return {
      show: false,
      list: [
        { status: 2, title: '资金扶持', content: '您已通过智能诊断评估，智能为您推荐1年期金融借贷产品', show: true, name: 'FMApplication' },
        { status: 1, title: '申请材料已提交', content: '部门审核中，请耐心等待进度反馈。', show: true },
        { status: 3, title: '部门通知', content: '产业基金已开放申请，您符合申请资格，点击下方查看进入申请页面。', show: true, name: 'IFDeclare' },
      ],
    };
  },
  methods: {
    hideItem(v, i) {
      v.show = false;
      // this.$set(this.list, i, v);
    },
    go(name) {
      this.$router.push({ name });
      this.show = false;
    },
  },
  computed: {
    nodata() {
      return this.list.filter((v) => {
        return v.show == true;
      }).length > 0
        ? false
        : true;
    },
  },
};
</script>

<style scoped lang="scss">
.notice-list {
  padding: 0 20px 16px;
}
.close {
  cursor: pointer;
}
.no-notoce {
  width: 260px;
  height: 190px;
  margin: 98px 21px 0 20px;
}

.notice-item {
  padding: 15px;
  border: 1px solid #eeeeee;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;
  width: 301px;
  font-size: 14px;
  color: #606266;
}

.notice-icon {
  font-size: 24px;
  margin-right: 13px;
}
.el-icon-success {
  color: #67c23a;
}
.el-icon-info {
  color: #999;
}
.el-icon-warning {
  color: #e6a23c;
}

.notice-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
  font-size: 15px;
}

.title {
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.btns {
  margin-top: 20px;
}
</style>

<template>
  <div id="app">
    <NavMenu v-show="$route.name !== 'Auth'" />
    <router-view style="padding-top: 60px"></router-view>
    <PublicFooter v-show="$route.name !== 'Auth'" ></PublicFooter>
    <Suspension v-show="$route.name !== 'Auth'"></Suspension>
    <LoginModel v-show="$route.name !== 'Auth' && $route.name !== 'Test'" ref="LoginModel"></LoginModel>
    <!-- <Debuger/> -->
    
  </div>
</template>

<script>
import NavMenu from './components/NavMenu.vue';
import PublicFooter from '@/components/PublicFooter';
import Suspension from '@/components/Suspension';
import LoginModel from '@/components/LoginModel';
import Debuger from "@/components/debuger.vue"

import Watermark from './assets/js/watermark'
import Vue from 'vue'
Vue.prototype.$Watermark = Watermark;
import {mapState} from "vuex"
export default {
  name: 'App',
  components: {
    Debuger,
    NavMenu,
    PublicFooter,
    Suspension,
    LoginModel,
  },
  computed: {
    ...mapState({
      userInfo:state=>{

       return state.userStore ? (state.userStore.adminVo || state.userStore.adminPersonalUserEntity || state.userStore.user) : {name:"未登录",userName:""}
      }
    })
  },
  watch:{
    userInfo:{
      deep:true,

      immediate: true,
      handler: function(nv){
        console.log("userInfo监听")
          Watermark.set(nv.name,nv.userName)
      }
    }
  },
  created() {
    this.$api.getAttachmentShowUrl().then((res) => {
      this.$store.commit('updateValue', { fileUrl: res.result });
    });
    this.$api.getTemplateAttachmentList().then((res) => {
      let list = res.result.sort(function (a, b) { return a.type - b.type});
      this.$store.commit('updateValue', { attachmentUrl: list });
    });
  },
  mounted() {
    window.App = this;
    
    this.$nextTick(() =>{
     console.log("当前登录信息",this.$store.state.userStore)
    })
  
    window.vBus.$on('logIn', () => {
      this.$nextTick(() => {
        this.$refs.LoginModel.centerDialogVisible = true;
        this.$refs.LoginModel.loginData = {};
      });
    });
  },
};
</script>

<style lang="scss">
@import './assets/css/index.scss';
@import './assets/css/queryTable.scss';
* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style-type: none;
}

#app {
  position: relative;
  min-height: calc(100vh - 168px);
  font-family: MicrosoftYaHei, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f5f5;
  font-size: 14px;
}
</style>
<!--
 * @Author: your name
 * @Date: 2020-12-03 08:57:50
 * @LastEditTime: 2020-12-03 16:09:43
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \enterprise\src\components\NavMenu.vue
-->
<template>
  <div class="nav-header">
    <div class="logo horizontal" @click="gohome"></div>
    <el-menu
      class="nav-menu"
      router
      :default-active="onRoutes"
      ref="menu"
      mode="horizontal"
      background-color="#fff"
      text-color="#333"
      active-text-color="#024A8F"
    >
      <template v-for="(item, index) in menuData">
        <el-submenu v-if="item.children" :index="index + ''" :key="index + ''" popper-class="popper-box">
          <template slot="title">
            <span slot="title">{{ item.res_name }}</span>
          </template>
          <template v-for="(subItem, subIndex) in item.children">
            <el-submenu v-if="subItem.children" :index="subIndex + '-2'" :key="subIndex + '-2'" popper-class="popper-children">
              <span slot="title">{{ subItem.res_name }}</span>
              <template template v-for="sub2Item in subItem.children">
                <el-menu-item v-if="sub2Item.open" :index="subItem.url" :key="sub2Item.res_name" @click="open(sub2Item.url)">
                  <span slot="title">{{ sub2Item.res_name }}</span>
                </el-menu-item>
                <el-menu-item v-else :index="sub2Item.url" :key="sub2Item.res_name">
                  <span slot="title">{{ sub2Item.res_name }}</span>
                </el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item v-else-if="subItem.open" :index="subItem.url" :key="subItem.res_name" @click="open(subItem.url)">
              <span slot="title">{{ subItem.res_name }}</span>
            </el-menu-item>
            <el-menu-item v-else :index="subItem.url" :key="subItem.res_name">
              <span slot="title">{{ subItem.res_name }}</span>
            </el-menu-item>
          </template>
        </el-submenu>
        <template v-else>
          <el-menu-item :index="item.url" :key="item.res_name">
            <span slot="title">{{ item.res_name }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import Menu from '@/assets/js/menu';
import router from '../router';

export default {
  name: 'NavMenu',
  data() {
    return {
      menuData: Menu,
    };
  },
  computed: {
    onRoutes() {
      const route = this.$route;
      const { meta, path } = route;
      // 可以在路由配置文件中设置自定义的路由路径到meta.activeMenu属性中，来控制菜单自定义高亮显示
      // meta中 有activeMenu 字段的子页面，都会显示高亮
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
  methods: {
    gohome() {
      this.$router.push({ name: 'HomePage' });
    },
    open(url) {
      window.open(url);
    },
  },
  beforeUpdate() {
    // console.log(this.$route);
  },
};
</script>

<style lang="scss">
.popper-box {
  .el-menu--popup {
    background-color: rgb(22, 56, 110) !important;
  }
  .el-submenu__title,
  .el-menu-item {
    background-color: rgb(22, 56, 110) !important;
    color: #fff !important;
    text-align: center;
    &:hover {
      background: rgba(22, 72, 152, 0.9) !important;
    }
  }
}
.nav-menu {
  .el-menu-item:hover {
    background-color: #fff !important;
    color: #024a8f !important;
  }
}
.nav-header {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: #fff;
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu--horizontal > .el-menu-item {
    margin: 0 30px;
    border: none !important;
    padding: 0;
    &.is-active {
      font-weight: 600;
      position: relative;
      &::before {
        position: absolute;
        content: '';
        width: 26px;
        height: 4px;
        background: #024a8f;
        border-radius: 3px;
        bottom: 0;
        left: 50%;
        margin-left: -13px;
      }
    }
  }
  .el-submenu {
    .el-submenu__title {
      border: none !important;
    }
    &.is-active {
      font-weight: 600;
      .el-submenu__title {
        position: relative;
        &::before {
          position: absolute;
          content: '';
          width: 26px;
          height: 4px;
          background: #024a8f;
          border-radius: 3px;
          bottom: 0;
          left: 50%;
          margin-left: -23px;
        }
      }
    }
  }

  .logo {
    float: left;
    margin-left: 83px;
    width: 248px;
    height: 60px;
    cursor: pointer;
    background: url('../assets/img/logo.png') 0 0 / contain no-repeat;
  }

  .nav-menu {
    float: right;
    padding-right: 60px;
  }
  .nav-item {
    margin: 0 40px !important;
  }
  @media screen and (max-width: 1170px) {
    .nav-menu {
      .nav-item {
        margin: 0 20px !important;
      }
    }
  }
}
</style>

<template>
  <div class="suspension">
    <!-- <div class="login">用户登录</div> -->
    <!-- <div class="item" @click="openDrawer">
      <el-button type="danger" icon="el-icon-bell" circle class="out"></el-button>
      <div class="tip">消息中心</div>
    </div> -->
    <div class="line"></div>
    <div class="item" v-if="userStore && userStore.adminVo">
      <img src="../assets/img/icon_mrtx@2x.png" alt="" />
      <div class="tip">{{ userStore.adminVo.name }}</div>
    </div>
    <div class="item" v-else-if="userStore && userStore.adminPersonalUserEntity">
      <img src="../assets/img/icon_mrtx@2x.png" alt="" />
      <div class="tip">{{ userStore.adminPersonalUserEntity.userName }}</div>
    </div>
    <div class="item" v-else-if="userStore && userStore.user">
      <img src="../assets/img/icon_mrtx@2x.png" alt="" />
      <div class="tip">{{ userStore.user.companyName }}</div>
    </div>
    <div class="item" v-else @click="login">
      <img src="../assets/img/icon_dl_s@2x.png" alt="" />
      <div class="tip">用户登录</div>
    </div>
    <div class="line"></div>
    <div class="item" @click="open()" v-if="userStore">
      <img src="../assets/img/icon_znkf_s@2x.png" alt="" />
      <div class="tip">智能客服</div>
      <div class="__chat_message__ __chat_message_a__" style="height: 700px" :class="show ? '' : 'hide'" :style="first ? 'display:block' : ''">
        <iframe
          src="https://msg-chatbot.ai-indeed.com/chat/tablet?robot=7z1h5mhft6asfavdjs78&amp;channel=jm4hezlmcodnnqxx&amp;t=0.9085989769648117"
          v-if="first"
        ></iframe>
      </div>
    </div>
    <div class="line"></div>
    <div class="item" @click="go('EnterprisesInPark')">
      <img src="../assets/img/icon_sqrz_s@2x.png" alt="" />
      <div class="tip">申请入驻</div>
    </div>
    <div class="line" v-if="userStore && userStore.user"></div>
    <div class="item" v-if="userStore && (userStore.adminVo || userStore.user)" @click="go('UserManage')">
      <img src="../assets/img/icon_dl_s@2x.png" alt="" />
      <div class="tip">账号管理</div>
    </div>
    <div class="line" v-if="userStore && userStore.user"></div>
    <div class="item" v-if="userStore && (userStore.adminVo || userStore.user)" @click="open('https://ojkcyzn.wenzhou.gov.cn:8093/attachment/static/qiyemuban/企业端-用户操作手册.doc')">
      <img src="../assets/img/icon-help-book.png" alt="" />
      <div class="tip">操作手册</div>
    </div>
    <div class="line" v-if="userStore && (userStore.adminVo || userStore.user)"></div>
    <div class="line" v-if="userStore && (userStore.adminVo || userStore.user)"></div>
    <div class="item" @click="out" v-if="userStore && (userStore.adminVo || userStore.user || userStore.adminPersonalUserEntity)">
      <el-button type="danger" icon="el-icon-switch-button" circle class="out"></el-button>
      <div class="tip">退出登录</div>
    </div>
    <drawer ref="drawer"></drawer>

    <!-- <div class="seach">
      <img src="../assets/img/icon_ss_n@2x.png" alt="" />
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { removeToken } from '@/http/auth';
import drawer from '@/components/drawer';

export default {
  name: 'suspension',
  data() {
    return {
      show: true,
      first: false,
      drawer: false,
    };
  },
  created() {},
  computed: {
    ...mapState({
      userStore: (state) => state.userStore,
    }),
  },
  components: {
    drawer,
  },
  methods: {
    go(name) {
      this.$router.push({
        name,
      });
    },
    open(url) {
      if(url){
        window.open(url)
        return;
      }
      if (this.first == true) {
        this.show = !this.show;
      } else {
        this.first = true;
      }
    },
    login() {
      window.vBus.$emit('logIn');
    },
    openDrawer() {
      this.$refs.drawer.show = true;
    },
    async out() {
      removeToken();
      localStorage.removeItem("SSO_TICKET")
       //退出个人登录
      if(this.userStore.adminPersonalUserEntity){
        
        try{
          // let res = await this.$api.loginOutPersonalLogin() 
          const loginout_url = `https://puser.zjzwfw.gov.cn/sso/newusp.do?action=logout&servicecode=ojkcyznxt`
          let loginoutWindow = window.open (`https://puser.zjzwfw.gov.cn/sso/newusp.do?action=logout&servicecode=ojkcyznxt`, 'newwindow', 'height=85, width=342, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no')  
          setTimeout(()=>{ loginoutWindow.close() },500)
        }catch(e){
          console.warn("登出失败")
        }

      }
      if(this.userStore.user){
        try{
          // let res = await this.$api.loginOutPersonalLogin() 
          const  loginoutUrl = (window.location.href)
          const rand = Math.random()
          let loginoutWindow = window.open (`https://www.zjzwfw.gov.cn/zjservice/member/login/logout.do?src=${loginoutUrl}&rand=${rand}`, 'newwindow', 'height=85, width=342, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no')  
          console.log({loginoutWindow})
          // loginoutWindow.onload =function (e){
          //   console.log("loginoutWindow.onload",e)
          // }
          setTimeout(()=>{ loginoutWindow.close() },5000)
        }catch(e){
          console.warn("登出失败")
        }

      }
      this.$store.commit('updateValue', { userStore: null });
      this.$router.push({
        name: 'HomePage',
      });
     
    },
  },
};
</script>

<style scoped lang="scss">
.__chat_message__.__chat_message_a__ {
  display: none;
  pointer-events: all;
  position: fixed;
  z-index: 2147483647;
  margin: 0;
  padding: 0;
  background: #fff;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  width: 64vw;
  height: 500px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.12);
  right: 84px;
  bottom: 94px;
  border-radius: 20px;
  animation: iframeShowAnimate 0.8s;
}

.__chat_message__.__chat_message_a__.hide {
  animation: iframeHideAnimate 0.2s forwards;
  // display: none;
}

.__chat_message__.__chat_message_a__ iframe {
  border: none;
  box-sizing: border-box;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.suspension {
  width: 67px;
  position: fixed;
  right: 0;
  top: 30%;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  z-index: 999;
  .login {
    background-image: linear-gradient(130deg, #6daeff 0%, #4393f8 100%);
    border-radius: 4px 4px 0 0;
    font-size: 12px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
  }
  .line {
    background-color: #efefef;
    height: 1px;
    margin: 0 10px;
  }
  .item {
    padding: 16px 8.5px;
    // width: 48px;
    // margin: 0 auto;
    cursor: pointer;
    img,
    .out {
      margin: 0 auto;
      width: 26px;
      height: 26px;
      line-height: 24px;
      padding: 0;
      display: block;
      margin-bottom: 4px;
    }
    /deep/ .el-button--danger {
      color: #fff;
      background-color: #135694;
      border-color: #135694;
    }
    .tip {
      text-align: center;
      font-size: 12px;
      color: #135694;
      line-height: 16px;
    }
  }
  .item:hover {
    background-image: linear-gradient(180deg, #fafcfe 0%, #eaf2f9 100%);
  }
  .seach {
    width: 48px;
    height: 48px;
    background: #f4f8fc;
    border-radius: 24px;
    margin: 6px auto 9px;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
      display: block;
      margin: 14px auto;
    }
  }
}

@keyframes iframeShowAnimate {
  0% {
    transform: scaleY(0.8);
  }
  25% {
    transform: scaleY(1.05);
  }
  50% {
    transform: scaleY(0.95);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes iframeHideAnimate {
  0% {
    transform: scaleX(1) scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0) scaleY(0);
    transform-origin: 100% 100%;
    margin-right: 13px;
    opacity: 0;
  }
}
</style>
import Vue from 'vue'
import vuex from 'vuex'

Vue.use(vuex)

const store = new vuex.Store({
  getters:{
    socialCode:state=>state.userStore.code
  },

  state: {
    userStore: null,
    config:{
      env:"TEST",
      curRoute:{}
    },
    notLeasedNum: 0,
    floorList: [],
    projectId: null,
    fileUrl: null,
    formDisabled:false,
    attachmentUrl: [
      {
        "attachmentUrl": "http://122.228.219.27:8088/attachment//static/qiyemuban/oujiangkouyibanxiangmu.doc",
        "remark": "2021温州瓯江口产业集聚区申请入园企业情况登记表(一般项目)",
        "type": "1"
      },
      {
        "attachmentUrl": "http://122.228.219.27:8088/attachment//static/qiyemuban/瓯江口口袋政策-上.pdf",
        "remark": "瓯江口口袋政策",
        "type": "3"
      },
      {
        "attachmentUrl": "http://122.228.219.27:8088/attachment//static/qiyemuban/工伤证人证言模板.doc",
        "remark": "工伤证人证言",
        "type": "5"
      },
      {
        "attachmentUrl": "http://122.228.219.27:8088/attachment//static/qiyemuban/工伤认定申请表2013.doc",
        "remark": "工伤认定申请表",
        "type": "4"
      },
      {
        "attachmentUrl": "http://122.228.219.27:8088/attachment//static/qiyemuban/2021年入驻资料模板.zip",
        "remark": "2021年安全应急类项目入驻申请资料模板",
        "type": "2"
      },
      {
        "attachmentUrl": "http://122.228.219.27:8088/attachment//static/qiyemuban/瓯江口产业集聚区各企业员工子女就学安排汇模板.xls.xlsx",
        "remark": "瓯江口产业集聚区各企业员工子女就学安排汇模板",
        "type": "6"
      },
      {
        "attachmentUrl": "http://122.228.219.27:8088/attachment//static/qiyemuban/员工子女入学流程说明.doc",
        "remark": "员工子女入学流程说明",
        "type": "7"
      }
    ]
  },
  mutations: {
    updateValue(state, payload) {
      state[Object.keys(payload)] = payload[Object.keys(payload)]
    }
  }

})
export default store

/**
 * 设置state
 * @param {string} key 
 * @param {any} value 
 */
export const setState = (key,value) => {
  store.commit('updateValue', { [key]: value });
}
